import React, { useState, useEffect, useMemo } from "react";
import { CSVLink } from "react-csv";

/* Config */
import config from "../../app/config.json";

/* Components */
import {
  Banner,
  Container,
  ControlledSelect,
  FormButton,
  LoadingScreen,
} from "../../utils/components/Common";
import DetailFormComponent from "../../utils/components/DetailForm";

/* APIs - Utils */
import { User } from "../user/userApi";
import { Participant } from "../participant/participantApi";
import { useGetParticipantsQuery } from "../participant/participantApi";
import { toastQueryError } from "../../utils/toasts";
import { createSelector } from "@reduxjs/toolkit";
import { formatDateTimeString, getDatetimeString } from "../../utils/datetime";

type DownloadsPanelProps = {
  user: User;
  isLoggedIn: boolean;
};

const Downloads = ({ user, isLoggedIn }: DownloadsPanelProps): JSX.Element => {
  /* https://sydneyuni.atlassian.net/browse/MOT-680 */

  const [processing, setProcessing] = useState(true);
  const [statusFilters, setStatusFilters] = useState<string[]>(
    config.constants.participant.downloads.statusFilters
  );
  const [hasLapsedFilters, setHasLapsedFilters] = useState<string[]>(
    config.constants.participant.downloads.hasLapsedFilters
  );

  const [hasGoneColdFilters, setHasGoneColdFilters] = useState<string[]>(
    config.constants.participant.downloads.hasGoneColdFilters
  );

  const selectParticipantsByFilters = useMemo(() => {
    return createSelector(
      (
        participants: Participant[],
        statusFilters: string[],
        hasGoneColdFilters: string[],
        hasLapsedFilters: string[]
      ) =>
        participants.filter((participant) => {
          return (
            statusFilters.includes(participant.status) &&
            hasGoneColdFilters.includes(participant.is_cold) &&
            hasLapsedFilters.includes(participant.has_lapsed)
          );
        }),
      (participants) => participants
    );
  }, []);

  const {
    data: allParticipants,
    filteredParticipants,
    isFetching,
    isLoading,
    isError,
    error,
  } = useGetParticipantsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      filteredParticipants: selectParticipantsByFilters(
        result?.data ?? [],
        statusFilters,
        hasGoneColdFilters,
        hasLapsedFilters
      ),
    }),
  });

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setStatusFilters(selectedValues);
  };
  const onHasLapsedFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setHasLapsedFilters(selectedValues);
  };
  const onHasGoneColdFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setHasGoneColdFilters(selectedValues);
  };
  useEffect(() => {
    if (isFetching || isLoading) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading, isFetching]);

  return (
    <>
      {user && isLoggedIn ? (
        <Container padding="px-16 py-6">
          {processing ? <LoadingScreen /> : null}

          <DetailFormComponent.Section>
            <DetailFormComponent.Header>
              Participants
            </DetailFormComponent.Header>
            <Banner
              heading="Participant Filter"
              message={`Participant filters for download. For Mac, hold command (cmd) and click. For Windows, hold control (ctrl) and click.
                      Select Download to download participants based on the selected filters.
                      Select Download All to download all participants.`}
            >
              <strong>{`Selected a total of ${statusFilters.length} status filters:`}</strong>
              <p>{`[${statusFilters.join(", ")}]`}</p>
              <strong>{`Selected a total of ${hasGoneColdFilters.length} is_cold filters:`}</strong>
              <p>{`[${hasGoneColdFilters.join(", ")}]`}</p>
              <strong>{`Selected a total of ${hasLapsedFilters.length} has_lapsed filters:`}</strong>
              <p>{`[${hasLapsedFilters.join(", ")}]`}</p>
            </Banner>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group inline>
                <DetailFormComponent.Label>Status</DetailFormComponent.Label>
                <ControlledSelect
                  selectType="stateTransitions"
                  isMultiple
                  defaultMultiple={statusFilters}
                  onChange={onStatusChange}
                />
                <DetailFormComponent.Label>
                  Has Gone Cold
                </DetailFormComponent.Label>
                <ControlledSelect
                  selectType="ynLiteral"
                  isMultiple
                  defaultMultiple={hasGoneColdFilters}
                  onChange={onHasGoneColdFilterChange}
                />
                <DetailFormComponent.Label>
                  Has Lapsed Eligibility Window
                </DetailFormComponent.Label>
                <ControlledSelect
                  selectType="tfNaLiteral"
                  isMultiple
                  defaultMultiple={hasLapsedFilters}
                  onChange={onHasLapsedFilterChange}
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            {filteredParticipants ? (
              <FormButton
                type="button"
                txtColor="text-white"
                bgColor="bg-emerald-500"
                bgHover="hover:bg-emerald-600"
              >
                <CSVLink
                  data={filteredParticipants}
                  headers={config.constants.participant.downloads.csvHeaders}
                  filename={`ParticipantsDownload-${formatDateTimeString(
                    getDatetimeString(),
                    "YYYY-MM-DD"
                  )}`}
                >
                  Download
                </CSVLink>
              </FormButton>
            ) : null}
            {allParticipants ? (
              <FormButton
                type="button"
                txtColor="text-white"
                bgColor="bg-emerald-500"
                bgHover="hover:bg-emerald-600"
              >
                <CSVLink
                  data={allParticipants}
                  headers={config.constants.participant.downloads.csvHeaders}
                  filename={`ParticipantsDownload-${formatDateTimeString(
                    getDatetimeString(),
                    "YYYY-MM-DD"
                  )}`}
                >
                  Download All
                </CSVLink>
              </FormButton>
            ) : null}
          </DetailFormComponent.Section>
        </Container>
      ) : (
        <Banner
          heading="Protected Route"
          message="This is a protected route. Authentication required."
        />
      )}
    </>
  );
};

export default Downloads;
